<template>
  <div class="conteudo-interno">
    <div class="nova-comunicacao-container">
      <h1>Usuários</h1>
      <div class="container-btns">
        <button class="btn-pattern btn-pattern_laranja-principal" @click="adicionarUsuario">Adicionar</button>
      </div>
      <form @submit.prevent="pesquisaForm" class="formulario-container">
        <div class="formulario-linha linha-tripla">
          <div class="formulario-item">
            <label class="formulario-item-label">Nome </label>
            <input type="text" v-model="nome" placeholder="Nome" />
          </div>
          <div class="formulario-item">
            <label class="formulario-item-label">CPF</label>
            <input type="text" v-mask="'###.###.###-##'" v-model="cpf" placeholder="CPF" />
          </div>
          <div class="formulario-item">
            <label class="formulario-item-label">Email</label>
            <input type="text" v-model="email" placeholder="Email" />
          </div>
          <div class="formulario-item">
            <label class="formulario-item-label">Celular</label>
            <input type="text" v-model="celular" placeholder="Celular" v-mask="'(##) #####-####'" />
          </div>
          <div class="formulario-item">
            <label class="formulario-item-label">Autorizada</label>
            <multiselect @input="listarComboAutorizada" v-model="idTipo" label="descricao" track-by="id" :options="tipo"
              :searchable="false" :multiple="false" placeholder="Selecione" select-label="Selecionar"
              deselect-label="Remover" selected-label="Selecionado" :allow-empty="true" />
          </div>
          <div class="formulario-item" v-if="idTipo && idTipo.length != 0">
            <label class="formulario-item-label">{{ verificarLabel() }}</label>
            <multiselect v-model="franquiaAutorizada" label="descricao" track-by="id" :options="verificarArray()"
              :searchable="true" :multiple="false" placeholder="Selecione" select-label="Selecionar"
              deselect-label="Remover" selected-label="Selecionado" :allow-empty="true"><span slot="noResult">Item não
                encontrado</span></multiselect>
          </div>
          <div class="formulario-item">
            <label class="formulario-item-label">Cargo</label>
            <select-cargo :idTipoAutorizada="idTipo" @segmentacao="idCargo = $event" />
          </div>
          <div class="formulario-item">
            <label class="formulario-item-label">Status</label>
            <multiselect v-model="idStatus" label="descricao" track-by="id" :options="status" :searchable="false"
              :multiple="false" placeholder="Selecione" select-label="Selecionar" deselect-label="Remover"
              selected-label="Selecionado" :allow-empty="true" />
          </div>

          <div class="formulario-item">
            <label class="formulario-item-label">Perfil</label>
            <multiselect v-model="perfilUsuario" label="descricao" track-by="id" :options="opcoesPerfil"
              :searchable="false" :multiple="false" placeholder="Selecione" select-label="Selecionar"
              deselect-label="Remover" selected-label="Selecionado" :allow-empty="true" />
          </div>

          <div class="formulario-item">
            <label class="formulario-item-label">E-mail ativado</label>
            <multiselect v-model="emailAtivado" label="label" track-by="value" :options="ativacaoEmail"
              :searchable="false" :multiple="false" placeholder="Selecione" select-label="Selecionar"
              deselect-label="Remover" selected-label="Selecionado" :allow-empty="true" />
          </div>

        </div>
        <div class="container-btns">
          <button type="button" class="btn-pattern btn-pattern_cinza-principal" @click="limparDados">
            Limpar
          </button>
          <button type="submit" class="btn-pattern btn-pattern_laranja-principal">Filtrar</button>
        </div>
      </form>
      <div v-if="semDados" class="tabela-sem-dados" key="Vazio">
        <p>Sem dados</p>
      </div>

      <Tabela v-else :colunas="colunas" :linhas="usuarios" :paginacao="true" :pagina="pagina" :totalPaginas="totalPaginas"
        :funcProximaPag="proximaPag" :funcAnteriorPag="anteriorPag" />
    </div>
  </div>
</template>

<script>
import Tabela from "@/components/Tabela/Tabela.vue";
import {
  listarUsuariosEspelho,
  listarCargos,
  listarStatus,
} from "@/services/espelho";
import {
  listarComboAutorizada,
  listarAutorizadasCompleto,
} from "@/services/autorizadas";
import selectCargo from "@/components/estrutura/select/Cargos.vue"
import Multiselect from "vue-multiselect";
import { opcoes } from '@/utils/json'
import { objects, functions } from '@/assets/js'

export default {
  name: "UsuariosListar",
  components: {
    Multiselect,
    Tabela,
    selectCargo
  },

  data() {
    return {
      colunas: [
        { key: "id", titulo: 'Id', tamanho: 'pequeno', link: false },
        { key: "nome", titulo: 'Nome', tamanho: 'grande-visivel', link: false },
        { key: "cpf", titulo: 'CPF', tamanho: 'medio-130', link: false },
        { key: "cargo", titulo: 'Cargo', tamanho: 'medio-130', link: false },
        { key: "nomeFantasia", titulo: 'Autorizada', tamanho: 'grande', link: false },
        { key: "status", titulo: 'Status', tamanho: 'medio-130', link: false },
        { key: "acesso-espelho", titulo: 'Acesso Espelho', tamanho: 'medio-110', link: false, func: this.urlBase },
        { key: "", titulo: '', tamanho: 'btns', link: true, func: this.detalhesUsuario },
      ],
      objReferencia: {},
      opcoesPerfil: opcoes.perfil,
      semDados: false,
      pagina: 1,
      qtdPagina: 10,
      totalPaginas: 0,
      usuarios: [],
      nome: "",
      cpf: "",
      idCargo: [],
      idTipo: [],
      idStatus: "",
      email: "",
      celular: "",
      emailAtivado: null,
      perfilUsuario: [],
      cargos: [],
      cargosFiltro: [],
      assistencias: [],
      franquias: [],
      tipo: [],
      status: [],
      franquiaAutorizada: [],
      ativacaoEmail: [
        { label: 'Sim', value: 1 },
        { label: 'Não', value: 0 }
      ]
    };
  },

  watch: {
    idTipo() {
      let idAssistencias = [1, 4, 7, 8, 9, 10];
      let idFranquias = [5, 6];
      if (this.idTipo.id == 1) return this.cargosFiltro = this.cargos.filter(item => idAssistencias.find(i => i == item.id));
      if (this.idTipo.id == 2) return this.cargosFiltro = this.cargos.filter(item => idFranquias.find(i => i == item.id));
    }
  },

  mounted() {
    Promise.all(this.popularSelects())
    this.popularUsuarios()
  },

  methods: {
    popularSelects() {
      return [
        listarStatus().then((resp) => {
          this.status = resp.data;
        }),
        listarCargos().then((resp) => {
          this.cargos = resp.data;
          this.cargosFiltro = resp.data;
        }),
        listarComboAutorizada().then((resp) => {
          this.tipo = resp.data.filter(tipo => tipo.id != 3);
        }),
      ]
    },
    detalhesUsuario(usuarioId, perfilId) {
      return `/usuarios/usuario/${usuarioId}/${this.filtroPerfil(perfilId)}`
    },
    filtroPerfil(filtro) {
      if (filtro == 'Administrativo' || filtro == 'Relatório' || filtro == 'Parceiro Resgate') {
        return 1
      } else {
        return 2
      }
    },
    verificarLabel() {
      return this.idTipo.id == 1 ? "Assistência" : "Franquia"
    },
    verificarArray() {
      return this.idTipo.id == 1 ? this.assistencias : this.franquias
    },
    listarComboAutorizada(autorizada) {
      /*
        Esta funcao é apenas disparada quando o usuário seleciona alguma opcao do select
        e verifica se ja fez alguma requisiçao para aquele id
      */
      this.franquiaAutorizada = []
      switch (autorizada.id) {
        case 1:
          // assistencia
          if (!this.assistencias.length) return listarAutorizadasCompleto(autorizada.id).then(res => {
            this.assistencias = res.data
          })
          break;
        case 2:
          // franquias
          if (!this.franquias.length) return listarAutorizadasCompleto(2).then(res => {
            this.franquias = res.data
          })
          break;
        default:
          console.log('erro na funcao listarComboAutorizada')
          break;
      }
    },
    getToken() {
      return localStorage.getItem("token");
    },
    urlBase(param) {
      return `${process.env.VUE_APP_SITE
        }/login/?espelho=${param}?token=${this.getToken()}`;
    },
    adicionarUsuario() {
      this.$router.push({ name: 'adicionar-usuario' });
    },
    limparDados() {
      this.nome = "",
        this.cpf = "",
        this.idCargo = [],
        this.idTipo = [],
        this.franquiaAutorizada = [],
        this.idStatus = [],
        this.celular = "",
        this.perfilUsuario = [],
        this.email = "",
        this.emailAtivado = null
    },
    filtro() {
      const data = {
        nome: this.nome,
        cpf: this.cpf.replace(/[^\d]+/g, ""),
        celular: this.celular.replace(/[^\d]+/g, ""),
        idTipoAutorizada: functions.verificarDadoValido(this.idTipo) ? this.idTipo.id : 0,
        idCargo: functions.verificarDadoValido(this.idCargo) ? this.idCargo.id : 0,
        idAutorizada: functions.verificarDadoValido(this.franquiaAutorizada) ? this.franquiaAutorizada.id : 0,
        idStatus: functions.verificarDadoValido(this.idStatus) ? this.idStatus.id : 0,
        perfilUsuario: functions.verificarDadoValido(this.perfilUsuario) ? this.perfilUsuario.id : 0,
        emailConfirmado: this.emailAtivado == null? null : this.emailAtivado.value,
        email: this.email,
        pagina: this.pagina,
        qtdPorPagina: this.qtdPagina,
      };
      return data
    },
    pesquisaForm() { // Funçao para evitar requisiçao desnecessária
      if (objects.objetosIguais(this.objReferencia, this.filtro())) { // caso todos os campos sao iguais
        return this.$store.commit("SET_MODAL", {
          ativado: true,
          mensagem: 'Nenhum campo foi alterado. Terá que mudar algum filtro para fazer uma nova busca',
          tipo: "erro",
        })
      }
      this.pagina = 1
      this.popularUsuarios()
    },
    popularUsuarios() {
      this.objReferencia = this.filtro();
      listarUsuariosEspelho(this.filtro()).then((resp) => {
        if (resp.data.listaUsuarios.length) {
          this.semDados = false
          this.usuarios = resp.data.listaUsuarios
          this.totalPaginas = resp.data.paginacao.totalPaginas
          return;
        }
        this.semDados = true
      });
    },
    proximaPag() {
      this.pagina++;
      this.popularUsuarios();
    },
    anteriorPag() {
      this.pagina--;
      this.popularUsuarios();
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>