<template v-once>
  <multiselect
    v-model="idCargo"
    label="descricao"
    track-by="id"
    :options="verificaTipo()"
    :searchable="false"
    :multiple="false"
    placeholder="Selecione"
    select-label="Selecionar"
    deselect-label="Remover"
    selected-label="Selecionado"
    @input="$emit('segmentacao', idCargo)"
  />
</template>

<script>
import { listaCargoPorAutorizada } from "@/services/autorizadas";
import { listarCargos } from "@/services/espelho";
import Multiselect from "vue-multiselect";
export default {
  props: ["idTipoAutorizada"],

  components: { Multiselect },

  data() {
    return {
      idCargo: [],
      cargosAssistencia: [],
      cargosFranquia: [],
      todosCargos: [],
    };
  },

  mounted() {
    this.buscaCargoPorAutorizada();
  },

  methods: {
    buscaCargoPorAutorizada() {
      listaCargoPorAutorizada(1).then((resp) => {
        this.cargosAssistencia = resp.data;
      });
      listaCargoPorAutorizada(2).then((resp) => {
        this.cargosFranquia = resp.data;
      });
      listarCargos().then((resp) => {
        this.todosCargos = resp.data;
      });
    },
    verificaTipo() {
      if (this.idTipoAutorizada.id == 1) return this.cargosAssistencia;
      if (this.idTipoAutorizada.id == 2) return this.cargosFranquia;
      return this.todosCargos;
    },
  },
};
</script>

<style scoped>
.select-cargo {
  max-width: 380px;
}
</style>